import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from 'gatsby';
import Shop from "../components/shop.jsx"

const Action = ({location}) => {
  
  return (
    <div>
        <Shop header={{
            title: "Hot Wheels Action",
            text: "Hot Wheels Action ist für Kinder die noch zu Jung für die Track Builder Reihe sind, aber die City Sets etwas zu Kindlich finden. Sie können die verschiedenen Reihen von Sets auch beliebig miteinander mischen, da alle Hot Wheels sind miteinander kompatibel sind.",
            defaultCategory: "Action"
        }} location={location} />
    </div>
  )
}

export default Action
